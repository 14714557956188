.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem;
    padding-bottom: 0;
    position: sticky;
    top: 0;
    z-index: 40;
}
   
.navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
}

.home-nav-item a {
    opacity: 0.5;
    font-size: 25px;
    transition: opacity 0.3s, color 0.3s;
    margin-right: 25px;
  }
  
  .home-nav-item a.active {
    opacity: 1;
  }

.navbar-links a {
    opacity: 0.5;
    font-size: 20px;
    transition: opacity 0.3s, color 0.3s;
    margin-right: 25px;
}

.home-nav-item {
    padding-bottom: 0px;
    list-style: none;
}

.home-nav-item .active {
    border-bottom: 2px solid white;
    color: white; 
    opacity: 1;
}

.navbar-links a:hover {
    opacity: 0.75;
    border-bottom: 2px solid white;
}

.navbar-links_logo{
    margin-right: 1.5rem;
}

.navbar-links_logo img{
    top: 70px;
    left: 70px;
    width: 100px;
    height: 100px;
    opacity: 1;
}

.navbar-links_container{
    display: flex;
    flex-direction: row;
}

.navbar-lang{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.nav-button{
    cursor: pointer; 
    width: 120px;
    min-height: 38px;
    font-size: 17px;
    background-color: transparent;
    border-radius: 5px 5px 5px 5px;
    border-color: white;
    color: white;
    border: 1px solid #FFFFFF;
}

select {
    color: white;
    background-color: transparent;
    padding: 10px;
    font-size: 16px;
    width: 100px;
}

.navbar-lang p{
    padding-left: 30px;
}

.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .flag-image {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .dropdown-arrow{
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    margin-left: 5px;
  }

  .language-item {
    display: flex;
    justify-content: space-between;
  }

  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: black;
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .dropdown-menu li {
    padding: 10px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .dropdown-menu li:hover {
    background-color: black;
    opacity: 0.6;
  }
  
  .dropdown-menu li span {
    margin-right: 10px;
  }
  
option {
    padding-right: 30px;
}

option::after {
    content: ""; 
    display: inline-block;
    width: 20px; 
    height: 20px;
    background-size: cover;
    border-radius: 50%;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}

option.english::after {
    background-image: url('../../assets/england.png');
}

option.japanese::after {
    background-image: url('../../assets/england.png');
}

option.korean::after {
    background-image: url('../../assets/england.png');
}

.navbar-links_container p,
.navbar-lang p,
.navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-size: 17px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.navbar-menu svg{
    cursor: pointer;
}

.navbar-menu svg {
  font-size: 2rem;
  cursor: pointer;
}

.dropdown-toggle.active .dropdown-menu {
  display: block; /* Show dropdown when toggle is active */
}

.q-dropdown-toggle.active .dropdown-menu {
  display: block; 
}


.navbar-mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  color: white;
  z-index: 999;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.3s ease;
  overflow-y: scroll;
}

.navbar-mobile-menu.fade-in {
  opacity: 1;
  pointer-events: auto;
}

.navbar-mobile-menu.fade-out {
  opacity: 0;
}

.navbar-mobile-menu_header {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.navbar-mobile-menu_close {
  cursor: pointer;
}

.navbar-mobile-menu_content {
  flex: 1;
  display: flex;
  padding-bottom: 30px;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

.navbar-icons {
  display: flex; 
  position: relative;
  flex-direction: row;
  align-items: center;
}

.navbar-icon {
  width: 30px;
  margin: 10px 20px;
}

@media screen and (max-width: 1023px) {

.navbar {
  padding: 1rem 2rem;
}

.navbar-links_logo img{
    top: 40px;
    left: 60px;
    width: 70px;
    height: 70px;
    opacity: 1;
}

  .navbar-links {
    display: none;
  }

  .navbar-menu {
    display: block;
  }

  .navbar-lang{
    display: none;
  }

  .h-m-nav-button{ 
    cursor: pointer; 
    margin-top: 25px;
    width: 120px;
    min-height: 38px;
    font-size: 17px;
    background-color: transparent;
    border-radius: 5px 5px 5px 5px;
    border-color: white;
    color: white;
    border: 1px solid #FFFFFF;
  }

  .home-nav-item a {
    margin-right: 0px;
    font-size: 22px;
    opacity: 0.2;
  }

  .home-mobile-blog-click {
    font-size: 22px;
    padding-top: 20px;
  }

  .m-navbar-icons {
    display: flex; 
    position: relative;
    flex-direction: row;
    align-items: center;
    padding-top: 30px;
  }

  .m-navbar-icon {
    width: 30px;
    margin: 10px 20px;
  }

  .q-dropdown {
    position: relative;
    padding-top: 20px;
    display: inline-block;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: black;
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 150px;
    font-size: 15px;
  }
  
  .dropdown-menu li {
    padding: 10px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .dropdown-menu li:hover {
    background-color: black;
    opacity: 0.6;
  }
  
  .dropdown-menu li span {
    margin-right: 0px;
    margin-left: 50px;
    line-height: 1.0;
  }
}