.italic {
  font-style: italic;
}

.t-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;
  position: sticky;
  top: 0;
  z-index: 40;
}

.t-navbar-links_logo{
    margin-right: 1.5rem;
}

.t-navbar-links_logo img{
top: 70px;
left: 70px;
width: 100px;
height: 100px;
opacity: 1;
}

.t-navbar-links_container{
    display: flex;
    flex-direction: row;
}

.t-navbar-lang{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.nav-button{
    width: 120px;
    min-height: 38px;
    font-size: 17px;
    background-color: transparent;
    border-radius: 5px 5px 5px 5px;
    border-color: white;
    color: white;
    border: 1px solid #FFFFFF;
}

select {
    color: white;
    background-color: transparent;
    padding: 10px;
    font-size: 16px;
    width: 100px;
}

.t-navbar-lang p{
    padding-left: 30px;
}

option {
    padding-right: 30px;
}

option::after {
    content: ""; 
    display: inline-block;
    width: 20px; 
    height: 20px;
    background-size: cover;
    border-radius: 50%;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}

option.english::after {
    background-image: url('../../assets/england.png');
}

option.japanese::after {
    background-image: url('../../assets/england.png');
}

option.korean::after {
    background-image: url('../../assets/england.png');
}


@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.centered-container {
  padding-top: 10dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: opacity 1s ease-in-out;
}

.centered-container img {
  position: relative;
  height: 180px;
  justify-content: center;
  z-index: -30;
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.fade-in {
  opacity: 0;
  animation: fadeInAnimation 0.5s ease-in forwards;
}

.fade-transition {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.second-text {
  letter-spacing: 5px;
  margin-top: 0px;
  font-size: 1.7em;
  color: white;
  }

.centered-container p {
  letter-spacing: 3px;
  margin-top: 0px;
  font-size: 1.7em;
  color: white;
  }

.centered-container p:last-child {
  font-size: 1.2em;
  color: white;
  padding-bottom: 50px;
}

.centered-container button {
  padding: 3px 25px;
  font-size: 1.2em;
  color: white;
  border: 1px solid white;
  cursor: pointer;
  margin-top: 50px;
}

.centered-container button:hover {
  background-color: #0056b3;
}

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.centered-content img {
  height: 20dvh;
  z-index: -10;
  opacity: 0.3;
}

.text-overlay {
  position: absolute;
  top: 35dvh;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 24px;
}

.test-input {
  position: absolute;
  top: 42dvh;
  text-align: center;
  border-bottom: 2px solid white;
  background-color: transparent;
  color: white;
  font-size: 50px !important;
}

.alphabet-container {
  padding-top: 7dvh;
}

.alphabet-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.alphabet-row button {
  width: 0;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 24px;
  margin-right: 0;
  margin-left: 0;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.3s;
}

.alphabet-row button:hover,
.alphabet-row button.active {
  opacity: 1;
  background-color: transparent;
}

.next-button {
  position: absolute;
  bottom: 50px;
  right: 50px;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.arrow-icon {
  margin-left: 5px;
}

.t-animation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.t-animation-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.t-animation-text {
  color: white;
  font-size: 24px;
  opacity: 0;
  animation: fadeInAnimation 1s ease-in forwards;
  transform: translateY(-80px);
  transition: opacity 1s ease-in-out;
  padding-bottom: 10px;
}

.t-animation-paragraph {
  color: white;
  font-size: 20px;
  width: 100%;
  opacity: 0;
  animation: fadeInAnimation 1s ease-in forwards;
  transform: translateY(-80px);
  transition: opacity 1s ease-in-out;
}

.move-up {
  transform: translateY(-120px);
  transition: transform 1s ease-in-out;
}

.test-button {
  color: white;
  background-color: transparent;
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 4px;
  margin-top: 20px;
  opacity: 0;
  animation: fadeInAnimation 1s ease-in forwards;
  transform: translateY(-20px);
  transition: opacity 1s ease-in-out;
}

.start-test-btn {
  color: white;
  background-color: transparent;
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 4px;
  opacity: 0;
  animation: fadeInAnimation 1s ease-in forwards;
  transform: translateY(-50px);
  transition: opacity 1s ease-in-out, background-color 0.3s, color 0.3s;
  border: 2px solid white; /* Add a border to make the button visible when transparent */
}

.start-test-btn:hover {
  background-color: white !important;
  color: black;
}

.fade-image-in.brighter {
  opacity: 1;
  filter: brightness(1.2);
  transition: opacity 1s ease-in-out;
  z-index: 99;
}

.fade-image-in.brighter.fadedOut {
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.fade-image-in.show {
  opacity: 0.6;
}

.fade-image-out {
  opacity: 0;
  transition: opacity 3s ease-in-out;
}

.break-line {
  display: none;
}

@media screen and (max-width: 1023px) {

  .t-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    position: sticky;
    top: 0;
    z-index: 40;
}

  .t-navbar-links_logo{
      margin-right: 1.5rem;
  }
 
  .t-navbar-links_logo img{
  top: 70px;
  left: 70px;
  width: 70px;
  height: 70px;
  opacity: 1;
  }

  .navbar-links {
    display: none;
  }

  .navbar-menu {
    display: block;
  }

  .t-navbar-lang{
    display: none;
  }

  .m-nav-button{
    cursor: pointer; 
    margin-top: 30px;
    width: 120px;
    min-height: 38px;
    font-size: 17px;
    background-color: transparent;
    border-radius: 5px 5px 5px 5px;
    border-color: white;
    color: white;
    border: 1px solid #FFFFFF;
  }

  .nav-item a {
    margin-right: 0px;
    font-size: 25px;
    opacity: 0.2;
  }

  .mobile-blog-click {
    font-size: 23px;
    padding-top: 20px;
  }

  .m-navbar-icons {
    display: flex; 
    position: relative;
    flex-direction: row;
    align-items: center;
    padding-top: 30px;
  }

  .m-navbar-icon {
    width: 30px;
    margin: 10px 20px;
  }

  .dropdown {
    position: relative;
    padding-top: 20px;
    display: inline-block;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: black;
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .dropdown-menu li {
    padding: 10px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .dropdown-menu li:hover {
    background-color: black;
    opacity: 0.6;
  }
  
  .dropdown-menu li span {
    margin-left: 60px;
  }

  .centered-container {
    padding-top: 8dvh;
  }
  
  .centered-container img {
    height: 160px;
  }

  .start-test-btn {
    font-size: 16px;
    padding: 8px 18px;
    transform: translateY(-14dvh);
  }

  .text-overlay {
    top: 32dvh;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-size: 24px;
  }
  
  .test-input {
    top: 35dvh;
    font-size: 36px !important;
  }
  
  .alphabet-container {
    padding-top: 25px;
  }

  .alphabet-container {
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .alphabet-row button {
    margin-top: 12px;
    font-size: 20px;
  }

  .next-button {
    font-size: 16px;
  }

  .t-animation-container {
    transform: translateY(0px);
  }

  .move-up {
    transform: translateY(-130px);
    transition: transform 1s ease-in-out;
  }

  .t-animation-text {
    font-size: 22px;
  }

  .t-animation-paragraph {
    font-size: 16px;
    width: 88vw;
    color: rgba(255, 255, 255, 0.8);
    transform: translateY(-15dvh);
  }
  .break-line {
    display: flex;
  }
}

