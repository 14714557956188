.community {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 120dvh;
    background-image: url('../../assets/community_bg.webp');
    background-size: cover;
    color: white;
    text-align: center;
    padding-bottom: 10%;
  }

  .c-line1 {
    font-size: 60px;
    line-height: 20px;
  }
  .c-line2{
    font-size: 80px;
  }
  .c-line3{
    font-size: 18px;
    opacity: 0.5;
  }
  
  .c-text-field {
    margin-top: 20px;
    padding: 10px;
    border-radius: 20px;
    border: 2px solid white;
    width: 200px; 
  }
  
  .c-image-row {
    padding-top: 30px;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    width: 30%;
  }
  
  .c-small-image {
    width: 35px;
    height: 35px;
    object-fit: contain;
    padding: 1px;
  }
  
.c-footer {
    padding-top: 100px;
    position: absolute;
    bottom: 30px;
    width: 90%;
}

.c-footerTexts {
    padding-top: 5px; 
    padding-left: 20%;
    padding-right: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

hr {
    color: white;
    border: none;
    height: 0.5px;
    background: white;
}

.company-name {
  opacity: 0.5;
}

.c-inputBox {
    width: 40%;
    display: flex;
    font-size: 18px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid white;
    border-radius: 20px;
    margin-top: 50px;
    padding: 10px;
    background: transparent;
}

#c-inputID {
  font-size: 18px;
  padding-left: 15px;
}
 
#c-inputID::placeholder {
    width: 350px;
    color: white;
    opacity: 0.5;
    background-color : transparent;
    border: none;
    outline: none;
  }

.register {
    cursor: pointer;
    font-size: 18px;
    font-weight: 100;
    padding-right: 20px;
    color: white;
}

.invalid {
    border-color: red;
}

.error-message {
    color: red;
    font-size: 1rem;
    margin-top: 15px;
}

@media screen and (max-width: 1023px) {
  .community {
    background-image: url('../../assets/community_bg.webp');
    height: 100dvh;
    background-size: cover;
    background-position: center;
    color: white;
    text-align: center;
    padding-bottom: 0px;
  }
  .c-line1 {
    font-size: 40px;
    font-weight: bold;
    line-height: 0;
  }
  .c-line2{
    font-size: 60px;
    font-weight: bold;
  }
  .c-line3{
    width: 50%;
    font-size: 16px;
    opacity: 0.5;
  }

  #c-inputID {
    font-size: 16px;
  }

  .c-inputBox {
    width: 80%;
    font-size: 16px;
    margin-top: 30px;
}

  .register {
    font-size: 14px;
  }

  .error-message {
    font-size: 0.9rem;
}

  .c-image-row {
    padding-top: 20px;
    display: flex;
    justify-content: space-around;
    margin-top: 25px;
    width: 70%;
  }
  
  .c-footer {
    opacity: 0.5;
    position: absolute;
    bottom: 20px;
    width: 80%;
}

.c-footerTexts {
    font-size: 12px;
    flex-direction: row;
    padding-top: 0px;
    padding-left: 0%;
    padding-right: 0%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
}

.c-footerTexts p{
  margin-top: 0px;
}

hr {
    color: white;
    border: none;
    height: 1px;
    background: white;
}

.company-name {
  line-height: 1;
}
}