@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.questions-page {
  animation: fadeInAnimation 1s ease-in forwards;
}

.q-wrapper {
  transform: translateY(-30%);
}
  
.survey-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 27dvh auto;
    z-index: 2;
    /* transition: opacity 0.5s ease-in-out; */
}
 
.q-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem;
    position: sticky;
    top: 0;
    z-index: 40;
}

.q-navbar-links_logo{
    margin-right: 1.5rem;
}

.q-navbar-links_logo img{
    top: 70px;
    left: 70px;
    width: 100px;
    height: 100px;
    opacity: 1;
}

.q-logo-text{
    display: flex;
    align-items: center;
    justify-content: center;
    top: 159px;
    left: 67px;
    width: 48px;
    height: 14px;
    color: white;
    text-align: center;
    opacity: 0.5;
}

.q-navbar-links_container{
    display: flex;
    flex-direction: row;
}

.q-navbar-lang{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flag-image {
  width: 24px;
  height: 24px; 
  border-radius: 50%;
  margin-right: 5px;
}

.q-dropdown {
  position: relative;
  display: inline-block;
}

.q-dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.q-dropdown-arrow{
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  margin-left: 5px;
}

.q-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: black;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.q-dropdown-menu li {
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.q-dropdown-menu li:hover {
  background-color: black;
  opacity: 0.6;
}

.q-dropdown-menu li span {
  margin-right: 10px;
}

.nav-button{
    width: 120px;
    min-height: 38px;
    font-size: 17px;
    background-color: transparent;
    border-radius: 5px 5px 5px 5px;
    border-color: white;
    color: white;
    border: 1px solid #FFFFFF;
}

.blog-click {
  color: white;
}

select {
    color: white;
    background-color: transparent;
    padding: 10px;
    font-size: 16px;
    width: 100px;
}

.q-navbar-lang p{
    padding-left: 30px;
}

option {
    padding-right: 30px;
}

option::after {
    content: ""; 
    display: inline-block;
    width: 20px; 
    height: 20px;
    background-size: cover;
    border-radius: 50%;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}

option.english::after {
    background-image: url('../../assets/england.png');
}

option.japanese::after {
    background-image: url('../../assets/england.png');
}

option.korean::after {
    background-image: url('../../assets/england.png');
}


.q-wrapper {
  text-align: center;
  color: white;
  position: relative;
  width: 80%;
  margin: 100px auto;
}

.background-image-container {
  height: 180px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -10;
  pointer-events: none;
  transition: opacity 1s ease-in-out;
}

.background-image-container.show {
  opacity: 0.7;
}

.fade-out {
  opacity: 0;
}

.fade-in {
  opacity: 1;
}

.question-counter {
  height: 150px;
  opacity: 0.8;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 2; 
}

.question-text {
  position: relative;
  top: 20px;
  font-size: 28px;
  padding-bottom: 20px;
}

.response-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  top: 60px;
}

.disagree-text,
.agree-text {
  opacity: 0.6;
  margin: 0 20px;
  direction: ltr;
}

.q-circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.q-circle {
  display: block;
  width: 45px;
  height: 45px;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 0 20px;
  cursor: pointer;
}

.q-circle.selected {
  background-color: white;
  box-shadow: 0 0 15px 5px rgba(255, 255, 255, 0.7);
}

.q-circle-label {
  font-size: 16px;
  color: black;
}

span {
  display: inline-block;
  margin: 10px;
  cursor: pointer;
  text-decoration: none;
}

.q-next-button {
  position: absolute;
  top: 40dvh;
  right: 0px;
  font-size: 22px;
  vertical-align: middle;
}

.q-back-button {
  position: absolute;
  top: 40dvh;
  left: 0px;
  font-size: 22px;
  vertical-align: middle;
}

.q-back-button img {
  margin-right: 10px;
  width: 22px;
  vertical-align: middle;
}

.q-next-button img {
  margin-left: 10px;
  width: 22px;
  vertical-align: middle;
}

.q-submit-button {
  position: absolute;
  top: 40dvh;
  font-size: 22px;
  right: 0px;
  vertical-align: middle;
}

.q-submit-button img{
  margin-left: 10px;
}

.submitted-container {
  text-align: center;
  color: white;
  position: relative;
  width: 80%;
  height: 100px;
  margin: 100px auto;
  opacity: 0;
  animation: fade-in 3s ease-in-out forwards;
}
 
.loading-result {
  padding-top: 110px;
  font-size: 20px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1023px) {

  .q-wrapper {
    text-align: center;
    color: white;
    position: relative;
    width: 96%;
    margin: 100px auto;
  }

  .navbar-menu {
    display: block;
  }

  .q-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    position: sticky;
    top: 0;
    z-index: 40;
  }

  .q-navbar-links_logo{
    margin-right: 1.5rem;
  }

  .q-navbar-links_logo img{
    top: 70px;
    left: 70px;
    width: 70px;
    height: 70px;    
    opacity: 1;
  }

  .q-navbar-lang{
    display: none;
  }

  .m-nav-button{
    cursor: pointer; 
    margin-top: 30px;
    width: 120px;
    min-height: 38px;
    font-size: 17px;
    background-color: transparent;
    border-radius: 5px 5px 5px 5px;
    border-color: white;
    color: white;
    border: 1px solid #FFFFFF;
  }

  .nav-item a {
    margin-right: 0px;
    font-size: 25px;
    opacity: 0.2;
  }

  .mobile-blog-click {
    font-size: 23px;
    padding-top: 20px;
  }

  .m-navbar-icons {
    display: flex; 
    position: relative;
    flex-direction: row;
    align-items: center;
    padding-top: 30px;
  }

  .m-navbar-icon {
    width: 30px;
    margin: 10px 20px;
  }

  .q-dropdown {
    position: relative;
    padding-top: 20px;
    display: inline-block;
  }

  .q-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: black;
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .q-dropdown-menu li {
    padding: 10px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .q-dropdown-menu li:hover {
    background-color: black;
    opacity: 0.6;
  }
  
  .q-dropdown-menu li span {
    margin-right: 10px;
  }

  .question-text {
    height: 10dvh;
    font-size: 20px;
    min-height: 5rem;
  }

  .survey-container {
    width: 96%;
    padding-left: 3%;
    padding-right: 3%;
    margin: 10dvh auto;
  }

  .question-counter {
    height: 10dvh;
    opacity: 0.8;
    position: absolute;
    left: 50%;
    bottom: 12dvh;
    transform: translateX(-50%);
    z-index: 2; 
  }
  
  .response-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    top: 60px;
    padding-left: 10px;
    padding-right: 10px;
  }
   
  .disagree-text,
  .agree-text {
    white-space: normal;
    word-break: keep-all;
    font-size: 11px;
    opacity: 0.6;
    margin: 0 1vw;
  }

  .q-circle {
    width: 35px;
    height: 35px;
    margin: 0 10px;
  }

  [prefers-color-scheme="dark"] .q-circle {
    opacity: 0.3;
}

  .q-next-button {
    position: absolute;
    top: 40dvh;
    right: 0px;
  }
  
  .q-back-button {
    position: absolute;
    top: 40dvh;
    left: 0px;
  }
  
  .q-submit-button {
    position: absolute;
    top: 40dvh;
    right: 0px;
  }
}

