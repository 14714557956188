*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    font-family: "classico-urw", sans-serif;
    scrollbar-width: none;
  }

html, body {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-y: scroll;
  height: 100dvh;
}

::-webkit-scrollbar {
  display: none;
  width: 0px;
}

body {
  overflow-x: hidden !important;
}
.container {
  max-width: 100% !important;
  overflow-x: hidden !important;
}

#community {
  height: 120dvh;
}

.fade-enter {
  opacity: 0;
} 
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.longBackground {
    background-position: center -130px;
    scroll-snap-type: none;
    scroll-snap-stop: none;
}

.element {
  scroll-snap-align: start;
  scroll-snap-stop: always;
  height: 100vh;
}

.home-element {
  scroll-snap-align: start;
  height: 100vh;
}

body {
    background: var(--color-bg)
}

a {
    color: unset;
    text-decoration: none;
}

.section__padding {
    padding: 4rem 6rem;
  }
  
  .section__margin {
    margin: 4rem 6rem;
  }

  @media screen and (max-width: 1023px) {
    #community {
      height: 100dvh;
    }
  }