.header {
    display: flex;
    /* overflow: hidden; */
    background-image: url('/public/bg_1.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.header-content{
    height: 100%;
    position: relative;
    flex: 1;
    padding-top: 19%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 50px;

}

.header-content h1 {
    z-index: 0;    
    text-align: center;
    font: 100px;
    color: #FFFFFF;
    opacity: 1;
}

.home_title_text {
    font-family: "classico-urw", sans-serif;
    font-size: 100px;
    line-height: 50px;
    font-weight: 100;
    font-display: swap;
}

@media (prefers-reduced-motion: no-preference) {
  .header-content {
    will-change: opacity, transform;
  }
}

.header-content p {
    z-index: 0;
    padding-top: 40px;
    text-align: center;
    font-size: 40px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
 
.header-content__input {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 30;
}

.take-test-btn {
    margin-top: 70px;
    cursor: pointer;
    width: 164px;
    min-height: 42px;
    font-size: 20px;
    background-color: transparent;
    border-radius: 5px 5px 5px 5px;
    border-color: white;
    color: white;
    border: 2px solid #FFFFFF;
}

.header-side{
    height: 100vh;
    position: relative;
}

.h-icons {
    position: absolute;
    right: 30px;
    top: 37%;
    display: flex; 
    flex-direction: column;
    align-items: center;
  }
  
  .h-icon {
    width: 30px;
    margin: 10px 0;
  }

  .h-email {
    position: absolute;
    right: 30px;
    top: 90%;
    display: flex; 
  }
  .h-email span {
    text-decoration: none;
  }
  .h-email_img {
    height: 30px;
  }


/* Media Query for Mobile Devices */
@media (max-width: 1023px) { 

  .header-content{
      padding-top: 35dvh;
    }
  
    .home_title_text {
      font-size: 60px;
    }
    
    .header-content p {
      font-size: 30px;
    }
    
    .take-test-btn {
      width: 120px;
      min-height: 36px;
      font-size: 16px;
    }
    
    .h-icons {
      display: none;
    }
    
    .h-email {
      display: none;
    }
  }