.apps {
  padding-bottom: 70px;
  position: relative;
  background: black;
  display: flex;
  height: 100vh; 
  overflow: hidden;
  box-sizing: border-box;
  transition: opacity 1s ease;
}

.fade-in {
  height: auto;
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.fade-out {
  height: auto;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.a-right-section {
  right: 90px;
  position: absolute;
  padding-top: 10%;
  width: 54%;
  color: white;
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: space-between;
  transition: opacity 0.5s ease;
}

.a-right-section.fade-enter {
  opacity: 0.5;
}

.a-right-section.fade-enter-active {
  opacity: 1;
}

.a-right-section.fade-exit {
  opacity: 1;
}

.a-right-section.fade-exit-active {
  opacity: 0.5;
}

.a-right-bottom-section {
  right: 90px;
  position: absolute;
  padding-top: 12%;
  width: 54%;
  color: white;
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: space-between;
  transition: opacity 0.5s ease;
}

.a-full-screen-image {
  height: 100vh;
  object-fit: cover;
  transition: opacity 0.7s ease;
  
}

.a-full-screen-image.fade-enter {
  opacity: 0.5;
}

.a-full-screen-image.fade-enter-active {
  opacity: 1;
}

.a-full-screen-image.fade-exit {
  opacity: 1;
}

.a-full-screen-image.fade-exit-active {
  opacity: 0.5;
}

.a-text-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: right;
}

.a-text-big {
    font-size: 111px;
    font-weight: bold;
  }
  
.a-text-medium {
    opacity: 0.3;
    font-size: 53px;
    padding-bottom: 5px;
  }

.a-text-small {
    padding-top: 10px;
    padding-left: 15dvw;
    font-size: 18px;
    font-weight: normal;
  }

  span {
    margin: 0px;
    text-decoration: none;
  }

.a-text-section hr {
  width: 70%;
  margin-left: 30%;
  color: white;
  height: 1px;
  opacity: 0.4;
}

.a-button-section {
  display: flex;
  position: relative;
  z-index: 3;
  right: 5%;
  top: 100%;
}

.a-button-container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.a-button-container > button, .a-button-container > p {
  margin: 0 5px;
}

.a-square-button {
  margin-bottom: 5px;
  background-color: grey;
  width: 55px;
  height: 55px;
  border-radius: 10px;
  text-align: center;
  border: none;
  transition: transform 0.3s ease;
}

.a-square-button.clicked {
  transform: scale(1.2);
}

.green {
    background-color: green;
  }
  
  .blue {
    background-color: blue;
  }

  .pink {
    background-color: pink;
  }

  .yellow {
    background-color: yellow;
  }

  .red {
    background-color: red;
  }

  .purple {
    background-color: purple;
  }

  .a-btn-section {
    position: relative;
    justify-content: right;
    display: flex;
  }

  .apps-icons {
    position: relative;
    padding-top: 55dvh;
    justify-content: right;
    display: flex; 
  }
  
  .apps-icon {
    width: 140px;
    margin: 30px 20px;
  }
  .ios-icon {
    height: 70px;
    width: 140px;
  }
  .android-icon {
    height: 70px;
    width: 173px;
  }

  @media (max-width: 1023px) {
    .apps {
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .a-full-screen-image {
      height: 100dvh;
    }
    
    .a-left-section {
      width: 90vw;
      justify-content: center;
      display: flex;
    }

    .a-square-button {
      width: 43px;
      height: 43px;
      transition: transform 0.5s ease;
    }

    .a-button-container {
      margin-top: 0dvh;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
    }

    .a-button-container > button, .a-button-container > p {
      margin: 0 10px;
    }
  
    .a-right-section {
      width: 100%;
      display: flex;
      padding-top: 8dvh;
      padding-left: 12vw;
      padding-right: 12vw;
      text-align: center;
      align-items: center;
      right: 0px;
      left: 0px;
      position: absolute;
      color: white;
      display: flex;
      flex-direction: column;
    }

    .a-right-bottom-section {
      width: 100%;
      display: flex;
      padding-top: 6dvh;
      padding-left: 12vw;
      padding-right: 12vw;
      text-align: center;
      align-items: center;
      right: 0px;
      left: 0px;
      position: absolute;
      color: white;
      display: flex;
      flex-direction: column;
    }
  
    .a-text-section {
      text-align: center;
    }
  
    .a-text-big {
      font-size: 55px;
      line-height: 1.0;
    }
  
    .a-text-medium {
      font-size: 30px;
    }
  
    .a-text-small {
      padding-left: 0;
      padding-right: 0;
      font-size: 16px;
      opacity: 0.8;
      user-select: none;
    }
  
    .a-text-section hr {
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
      margin-top: 5px;
      margin-bottom: 5px;
    }
   
    .a-button-section {
      order: 2;
      justify-content: center;
      display: flex;
      position: absolute;
      padding-left: 10vw;
      padding-right: 10vw;
      left: 0px;
      right: 0px;
      top: 0px;
    }

    .a-btn-section {
      order: 1;
      width: 90vw;
      padding-left: 5vw;
      padding-right: 5vw;
      justify-content: center;
      display: flex;
      padding-top: 6dvh;
    }

    .apps-icons {
      order: 2;
      justify-content: center;
      margin-top: 10.0dvh;
    }
    
    .ios-icon {
      height: 50px;
      width: 100px;
      margin-right: 20px;
    }
    .android-icon {
      height: 50px;
      width: 133px;
      margin-left: 20px;
    }
  }