.team {
  position: relative;
  padding-bottom: 70px;
  height: 110vh; 
  justify-content: center;
  overflow: hidden;
}

.t-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.t-page.transitioning {
  opacity: 0;
}

.t-page.fade-in {
  opacity: 1;
  visibility: visible;
}

.t-page.fade-out {
  opacity: 0;
  visibility: hidden;
}

.t-background-img {
  height: 100vh;
  flex: 1;
  background-repeat: no-repeat;
}

.t-page1 {
  background-repeat: no-repeat;
  background-position: 40dvw -15dvh;
  padding-left: 15%;
  min-height: 110vh;
}

.t-page2 {
  background-repeat: no-repeat;
  background-position: -20dvw -15dvh;
  padding-left: 20%;
  min-height: 110vh;
}

.t-page-indicators {
  display: flex;
  gap: 10px;
  align-items: center;
}

.t-indicator {
  background-color: #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.t-indicator.active {
  background-color: #333;
}
  
  .t-text-content1 {
    position: absolute;
    justify-content: center;
    color: white;
    flex: 1;
    width: 80%;
    max-width: 1200px;
    z-Index: 2;
    user-select: none;
  }

  .t-text-content2 {
    position: absolute;
    justify-content: top;
    padding-top: 30dvh;
    right: 5dvw;
    color: white;
    flex: 1;
    width: 60%;
    max-width: 650px;
    z-Index: 2;
    user-select: none;
  }

  .t-title1 {
    font-size: 40px;
    font-weight: bold;
    opacity: 0.4;
    line-height: 1.0;
  }
  .t-subtitle1 {
    font-size: 50px;
    font-weight: bold;
    padding-left: 50px;
    line-height: 1.0;
  }
  .t-paragraph1 {
    font-size: 19px;
    line-height: 1.5;
  }

  .t-title2 {
    font-size: 52px;
    color: #FFD800;
  }
  .t-subtitle2 {
    font-size: 32px;
    color: #FFD800;
    opacity: 0.4;
  }
  
  .t-title3 {
    font-size: 52px;
    color:#FF084B;
  }
  .t-subtitle3 {
    font-size: 32px;
    color:#FF084B;
    opacity: 0.4;
  }
  
  .t-title4 {
    font-size: 52px;
    color:#40FFE9;
  }
  .t-subtitle4 {
    font-size: 32px;
    color:#40FFE9;
    opacity: 0.4;
  }

  .t-title5 {
    font-size: 52px;
    color:#4073FF;
  }

  .t-subtitle5 {
    font-size: 32px;
    color:#4073FF;
    opacity: 0.4;
  }

  .t-paragraph2 {
    font-size: 19px;
    margin-bottom: 15px;
    line-height: 1.5;
  }

  .t-page-indicators {
    color: white;
    position: absolute;
    bottom: 15%;
    right: 120px;
    z-index: 5;
  }
  
  .t-indicator {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #ccc;
    opacity: 0.2;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .t-indicator.active {
    width: 30px;
    height: 30px;
    border-radius: 0; /* Square */
    opacity: 1.0;
    background-color: white;
  }
  
  @media screen and (max-width: 1023px) {
    .team {
      min-height: 100vh;
      padding-bottom: 40px;
      display: flex;
      flex-direction: column;
    }

    .t-background-img {
      min-height: 100vh;
      display: flex;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .t-page {
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      background-size: cover;
      transition: opacity 0.3s ease-in-out;
    }

    .t-page1,
    .t-page2 {
      background-position: -20dvw -15dvh;
      padding-left: 20%;
      min-height: 95vh;
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .t-page1 {
      padding-left: 10%;
      padding-bottom: 15dvh;
    }
  
    .t-text-content1,
    .t-text-content2 {
      position: relative;
      width: 80%;
      max-width: none;
      text-align: center;
    }
  
    .t-subtitle1 {
      padding-left: 0;
    }
  
    .t-title2,
    .t-title3,
    .t-title4,
    .t-title5 {
      font-size: 50px;
      line-height: 1;
    }
  
    .t-subtitle2,
    .t-subtitle3,
    .t-subtitle4,
    .t-subtitle5 {
      font-size: 40px;
    }
  
    .t-paragraph1,
    .t-paragraph2 {
      font-size: 16px;
    }

    .t-paragraph1 {
      max-height: 260px;
      overflow-y: auto; 
    }

    .t-page-indicators {
      color: white;
      position: absolute;
      top: 15%;
      bottom: 75%;
      right: 50px;
      gap: 3px;
      z-index: 5;
    }

    .t-indicator {
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #ccc;
      opacity: 0.2;
      margin: 0 5px;
      cursor: pointer;
    }
    
    .t-indicator.active {
      width: 25px;
      height: 25px;
      border-radius: 0; /* Square */
      opacity: 1.0;
      background-color: white;
    }

  .t-text-content2 {
    position: absolute;
    justify-content: top;
    padding-top: 25dvh;
    margin-right: auto;
    color: white;
    flex: 1;
    width: 60vw;
    top: 5dvh;
    left: 12%;
    right: 30%;
    max-width: 650px;
    z-Index: 2,
  }
  }