.About1 {
  overflow-x: hidden;
  height: 100vh;
  background-image: url('/public/bg_2.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  }
.About2 {
  overflow-x: hidden;
  height: 100vh;
  background-image: url('/public/bg_3.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.About3 {
  overflow-x: hidden;
  height: 100vh;
  background-image: url('/public/bg_4.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
  
  .section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    padding: 10%;
  }
  
  .squares-container {
    position: relative;
    width: 38.5%;
    height: 59vh;
  }
  
  .square {
    position: absolute;
    width: 18vw;
    height: 18vw; 
    border: 0.5px solid rgba(255, 255, 255, 0.5);
    background-color: transparent;
  }
  
  .square1 {
    top: 5%;
    left: 0;
  }
  
  .square2 {
    bottom: 5%;
    right: 0;
  }

  .inverted .square1 {
    top: 5%;
    right: 0;
    left: unset;
  }
  
  .inverted .square2 {
    bottom: 5%;
    left: 0;
    right: unset;
  }
  
  .about-big-text {
    color: white;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .about-big-text .line1 {
    font-size: 5rem;
  }
  
  .about-big-text .line2 {
    font-size: 3rem;
  }
  
  .about-big-text .line3 {
    font-size: 3rem;
  }

  .about-big-text .line4 {
    font-size: 5rem;
    padding-left: 180px;
    padding-bottom: 20px;
  }
  
  .about-big-text .line5 {
    font-size: 3rem;
  } 
  
  .about-big-text .line6 {
    font-size: 4rem;
    padding-right: 200px;
    padding-top: 20px;
  }
   
  .long-text1 {
    font-size: 18px;
    color: white;
    width: 55%;
    line-height: 26px;
    word-spacing: 1px;
  }
  .long-text2 {
    font-size: 18px;
    color: white;
    width: 55%;
    line-height: 25px;
    word-spacing: 1px;
  }
  .m-long-text1 {
    font-size: 17px;
    color: white;
    width: 55%;
    line-height: 25px;
    word-spacing: 1px;
    display: none;
  }
  .m-long-text2 {
    font-size: 17px;
    color: white;
    width: 50%;
    line-height: 25px;
    word-spacing: 1px;
    display: none;
  }
  
  .inverted .squares-container {
    order: 2;
  }
  
  .text-center {
    flex-direction: column;
    justify-content: center;
  }
  
  .centered-text {
    text-align: center;
    line-height: 30px;
    color: white;
    border: 1px solid white;
    padding: 2%;
    width: 60%;
    margin: 0 auto;
    height: 85vh;
    padding: 70px;
    display: inline-block;
    height: auto;
  }

  .title{
    font-size: 60px;
    padding-bottom: 30px;
  }

  .subtitle{
    font-size: 40px;
    padding-bottom: 20px;
  }

  @media screen and (max-width: 1023px) {
    .About1 {
      height: 100dvh;  
      }
    .About2 {
      height: 100dvh;
    }
    .About3 {
      height: 100dvh;
    }
    .section {
      height: 100dvh;
      justify-content: center;
    }
    .About {
      height: auto;
    }
    .section {
      flex-direction: column;
      padding: 5%;
    }
  
    .squares-container {
      width: 100%;
      height: 35dvh;
      margin-bottom: 20px;
    }
  
    .square {
      width: 27vw;
      height: 27vw;
    }

    .square1 {
      top: 4dvh;
      left: 22vw;
    }
    
    .square2 {
      bottom: 4dvh;
      right: 22vw;
    }

    .inverted {
      padding-top: 10dvh;
    }
  
    .inverted .square1 {
      top: 3.2dvh;
      right: 23vw;
      left: unset;
    }
    
    .inverted .square2 {
      bottom: 3.2dvh;
      left: 23vw;
      right: unset;
    }

    .inverted .squares-container {
      order: -1;
    }
    
    .inverted .long-text1 {
      order: 1;
    }
    .inverted .long-text2 {
      order: 1;
    }
  
    .about-big-text .line1 {
      font-size: 2.6rem;
      padding: 0;
    }

    .about-big-text .line4 {
      font-size: 2.3rem;
      padding: 0;
    }
  
    .about-big-text .line2,
    .about-big-text .line3,
    .about-big-text .line5 {
      font-size: 1.6rem;
    }
  
    .about-big-text .line6 {
      font-size: 2.3rem;
      padding: 0;
    }
  
    .long-text1 {
      display: none;
    }
    .m-long-text1 {
      display: flex;
      width: 100%;
      text-align: center;
      opacity: 0.8;
      padding-left: 25px;
      padding-right: 25px;
      font-size: 16px;
      line-height: 1.3;
    }
    .long-text2 {
      display: none;
    }
    .m-long-text2 {
      display: flex;
      width: 100%;
      text-align: center;
      opacity: 0.8;
      padding-left: 25px;
      padding-right: 25px;
      font-size: 16px;
      line-height: 1.3;
    }
 
    .text-center {
      padding-top: 60px;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }

    .centered-text {
      font-size: 15px;
      width: 93%;
      padding: 20px;
      margin-top: 5dvh;
      margin-bottom: 20px;
      line-height: 1.4;
    }
  
    .title {
      font-size: 35px;
      padding-bottom: 0px;
    }
  
    .subtitle {
      font-size: 17px;
      padding-bottom: 10px;
    }

    .rest-text {
      opacity: 0.8;
    }
  }