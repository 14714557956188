.blog-page {
  position: relative;
  display: flex;
  flex-direction: column;
}

.b-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  position: sticky;
  top: 0;
  z-index: 40;
}

.b-navbar-links_logo{
  margin-right: 1.5rem;
}

.b-navbar-links_logo img{
top: 70px;
left: 70px;
width: 100px;
height: 100px;
opacity: 1;
}

.b-logo-text{
  display: flex;
  align-items: center;
  justify-content: center;
  top: 159px;
  left: 67px;
  width: 48px;
  height: 14px;
  color: white;
  text-align: center;
  opacity: 0.5;
}

.b-navbar-links_container{
  display: flex;
  flex-direction: row;
}

.b-navbar-lang{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.b-dropdown {
  position: relative;
  display: inline-block;
}

.b-dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.b-dropdown-arrow{
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  margin-left: 5px;
}

.b-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: transparent;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.b-dropdown-menu li {
  padding: 10px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.b-dropdown-menu li:hover {
  background-color: black;
  opacity: 0.6;
}

.b-dropdown-menu li span {
  margin-right: 10px;
}

.nav-button{
  width: 120px;
  min-height: 38px;
  font-size: 17px;
  background-color: transparent;
  border-radius: 5px 5px 5px 5px;
  border-color: white;
  color: white;
  border: 1px solid #FFFFFF;
}

.blog-click {
  margin-left: 0px;
  margin-right: 10px;
  color: white;
}

select {
  color: white;
  background-color: transparent;
  padding: 10px;
  font-size: 16px;
  width: 100px;
}

.b-navbar-lang p{
  padding-left: 30px;
}

option {
  padding-right: 30px;
}

option::after {
  content: ""; 
  display: inline-block;
  width: 20px; 
  height: 20px;
  background-size: cover;
  border-radius: 50%;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

option.english::after {
  background-image: url('../../assets/england.png');
}

option.japanese::after {
  background-image: url('../../assets/england.png');
}

option.korean::after {
  background-image: url('../../assets/england.png');
}


.category-dropdown {
  position: absolute;
  z-index: 10;
  padding-top: 450px;
  padding-left: 100px;
}

.category-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: none;
  font-size: 16px;
  padding: 8px 40px 8px 10px;
  cursor: pointer;
  background-image: url('../../assets/down_arrow.png');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
}

.category-select::-ms-expand {
  display: none;
}

.category-select option {
  background-color: white;
  color: black;
  padding: 8px;
}

.page-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center; 
  height: 600px;
  width: 100%; 
  z-index: -1;
  position: absolute;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
}

.page-header::before {
  content: "";
  position: absolute;
  top: 90%;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(transparent, black);
  pointer-events: none;
}

.page-header p {
  font-size: 70px;
  color: white;
  z-index: 2;
}

.page-header .blog-header {
  font-size: 90px;
  color: white;
  z-index: 2; 
}

  .sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 1rem;
    gap: 1rem;
  }
  
  .posts {
    color: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* 3 columns */
    padding: 3rem;
    z-index: 2;
    position: relative;
    width: max-content; 
    padding-top: 170px;
    margin: 200px auto 0 auto;
    column-gap: 70px;
}
  
  .post {
    width: 270px; 
    height: 450px; 
    border-radius: 15px;
    overflow: hidden;
    margin: 40px;
    cursor: pointer;
    position: relative;
  }
  
  .post-image {
    height: 60%;
    position: relative;
  }
  
  .post-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .post-image .overlay-top-left {
    position: absolute;
    width: 40px;
    height: 60px;
    top: 0px;
    left: 15px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 7px;
    border-radius: 0px;
  }
  
  .post-image .overlay-bottom-right {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 50px; 
    height: 50px;
    border-radius: 50%; 
    object-fit: cover;
  }

  .post-contents {
    padding: 15px;
    background-color: #262626;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .post-description {
    margin-top: 5px;
    font-size: 12px;
    opacity: 0.5;
  }

  .pagination {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 20px 0;
    padding-bottom: 70px;
  }
  
  .pagination p {
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 10px;
  }

  .pagination p.active {
    color: black;
    background-color: white;
  }

  .pagination-arrow {
    font-size: 16px;
    color: #555;
  }
  
  
  h2 {
    font-size: 1.5rem;
  }

  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; 
  }
  
  .modal-content {
    color: white;
    background: black; 
    width: 70%;
    padding: 20px;
    border-radius: 10px;
    height: 80vh; 
    overflow-y: auto; /* Enable vertical scrolling if the content overflows */
    }

  .modal-bottom {
    color: white;
  }

  .blog-post-detail {
    width: 100%;
    padding: 150px;
    padding-top: 350px;
    z-index: 20;
  }

  .modal-header {
    position: relative;
    width: 100%;
    height: 40vh;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .modal-header-image {
    width: 100%;
    object-fit: cover;
  }
  
  .overlay-top-left,
  .overlay-top-right {
    position: absolute;
    display: flex;
    gap: 5px;
  }
  
  .overlay-top-left {
    top: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 5px 10px;
    border-radius: 0px;
    flex-direction: column;
  }

  .overlay-top-left p{
    text-align: center;
    font-weight: bold;
    line-height: 1.0;
  }

  .blog-date {
    font-size: 20px;
  }

  .overlay-top-left2 {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 50px;
    height: 70px;
    gap: 5px;
    top: 0px;
    left: 30px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 7px;
    border-radius: 0px;
  }

  .overlay-top-left2 p{
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    line-height: 1.0;
  }

  .blog-date2 {
    font-size: 30px;
  }
  
  .overlay-top-right {
    display: flex;
    color: white;
    top: 15px;
    right: 15px;
    gap: 15px;
  }
  
  .overlay-top-right > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .blog-icons {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex; 
    flex-direction: column;
    align-items: center;
  }
  
  .blog-icon {
    width: 0%;
    margin: 10px 0;
  }

  .blog-icon-1 img{
    width: 20px;
  }
  .blog-icon-2 img{
    z-index: 30;
    width: 30px;
    margin: 10px;
  }
  
  .overlay-bottom-right {
    position: absolute;
    bottom: 10px;
    right: 10px;
    border-radius: 50%;
  }
  
  .modal-body {
    background-color: rgb(26,26,26);
    color: white;
    padding-top: 50px;
    padding-left: 90px;
    padding-right: 90px;
    padding-bottom: 50px;
  }
  
  .modal-body h2 {
    text-align: center;
    font-size: 26px;
    margin-bottom: 30px;
  }
  
  .modal-body p {
    font-size: 17px;
    line-height: 1.6;
  }
  
  .modal-body button {
    margin-top: 20px;
  }
  
  .share-close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    background: none;
    border: none;
    width: 25px;
    height: 25px;
  }

  .back-home {
    padding-top: 70px;
    justify-content: center;
  }

  /* Styles for the Share Popup */
.share-popup {
  background-color: black;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 200px;
  width: 450px;
  border: 1px solid #ddd;
  z-index: 1000;
  padding: 10px;
  box-sizing: border-box;
}

.sns-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-row1 {
  padding-top: 10px;
  display: flex;
  justify-content: space-evenly;
}
.button-row2 {
  padding-top: 10px;
  display: flex;
  justify-content: space-evenly;
}

.button-share + .button-share {
  margin-left: 10px;
}

.button-share{
  margin-top: 0px;
  margin-bottom: 0px;
}

.fb-share, .copy-link-button {
  border: none;
  background: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin: 0px;
  padding: 0px;
}

.copy-link {
  width: 40px;
  height: 40px;
}

.popup-header {
  display: flex;
  justify-content: top;
}

.close-button {
  cursor: pointer;
  background: none;
  border: none;
  width: 25px;
  height: 25px;
}

.share-popup button {
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.banner {
  display: flex;
  justify-content: space-between;
  background-image: url('../../assets/bannerimg.webp');
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center; 
  height: 300px;
}

.banner-left{
  padding-top: 70px;
  padding-left: 150px;
  color: white;
}

.b-line1 {
  font-size: 40px;
}

.b-line2 {
  font-size: 20px;
  padding-top: 20px;
}

.banner-icons {
  padding-right: 180px;
  padding-top: 125px;
}

.banner-icons img{
  width: 35px;
  margin-left: 70px;
}

/* .banner-icons img:last-child {
  width: 45px;
} */

.b-text-field {
  margin-top: 20px;
  padding: 10px;
  border-radius: 20px;
  border: 2px solid white;
  width: 200px; 
}

.b-inputBox {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 440px;
  align-items: center;
  border: 1px solid white;
  border-radius: 20px;
  padding: 12px;
  background: transparent;
}

input[type="text"], textarea {
  font-size: 18px;
  width: 320px;
  color: white;
  background-color : transparent;
  border: none;
  outline: none;
}

.register {
  cursor: pointer;
  font-weight: 100;
  padding-right: 20px;
}

.invalid {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
}

.b-footer {
  padding-top: 90px;
  position: relative;
  bottom: 50px;
  width: 100%;
}

.b-footerTexts {
  padding-top: 10px;
  padding-left: 30%;
  padding-right: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

@media (min-width: 608px) and (max-width: 1023px) {

  .b-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    position: sticky;
    top: 0;
    z-index: 40;
  }

  .b-navbar-links_logo img{
    top: 60px;
    left: 10px;
    width: 70px;
    height: 70px;
    opacity: 1;
  }

  .navbar-links {
    display: none;
  }

  .navbar-menu {
    display: block;
  }

  .b-navbar-lang{
    display: none;
  } 

  .m-nav-button{
    cursor: pointer; 
    margin-top: 30px;
    width: 120px;
    min-height: 38px;
    font-size: 17px;
    background-color: transparent;
    border-radius: 5px 5px 5px 5px;
    border-color: white;
    color: white;
    border: 1px solid #FFFFFF;
  }

  .nav-item a {
    margin-right: 0px;
    font-size: 25px;
    opacity: 0.2;
  }

  .mobile-blog-click {
    font-size: 23px;
    padding-top: 20px;
  }

  .m-navbar-icons {
    display: flex; 
    position: relative;
    flex-direction: row;
    align-items: center;
    padding-top: 30px;
  }

  .m-navbar-icon {
    width: 30px;
    margin: 10px 20px;
  }

  .dropdown {
    position: relative;
    padding-top: 20px;
    display: inline-block;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: black;
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .dropdown-menu li {
    padding: 10px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .dropdown-menu li:hover {
    background-color: black;
    opacity: 0.6;
  }
  
  .dropdown-menu li span {
    margin-right: 10px;
  }
  .page-header {
    height: 450px;
  }
  
  .page-header p {
    font-size: 50px;
  }
  
  .page-header .blog-header {
    font-size: 70px;
  }

  .blog-icons {
    display: none; 
  }

  .category-dropdown {
    position: absolute;
    z-index: 10;
    padding-top: 400px;
    padding-left: 30px;
  }

  .posts {
    color: white;
    display: grid;
    grid-template-columns: 1fr 1fr; /* 2 columns */
    padding: 3rem;
    width: auto; 
    padding-top: 130px;
    margin: 200px auto 0 auto;
    column-gap: 30px;
    justify-content: center;
}
  
  .post {
    width: 92%; 
    margin-left: 4%;
    margin-right: 4%;
    height: 400px;
    background-color: #262626;
  }

  .post-image .overlay-top-left {
    position: absolute;
    width: 56px;
    height: 75px;
    font-size: 20px;
    top: 0px;
    left: 15px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 7px;
    border-radius: 0px;
  }

  .post-contents h3 {
    font-size: 20px;
  }

  .post-description {
    margin-top: 10px;
    font-size: 13px;
  }

  .overlay-top-left2 {
    position: absolute;
    width: 56px;
    height: 75px;
    font-size: 25px;
    top: 0px;
    left: 30px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 7px;
    border-radius: 0px;
  }

  .blog-date {
    font-size: 30px;
  }
  
  .post-image {
    height: 60%;
    position: relative;
  }

  .blog-post-detail {
    position: absolute;
    z-index: 99;
    margin: 0;
    padding: 0;
  }

  .blog-icon-2 img{
    z-index: 30;
    width: 20px;
    margin: 5px;
  }
  
  .share-popup {
    background-color: black;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 25vh;
    width: 70vw;
    border: 1px solid #ddd;
    z-index: 1000;
    padding: 10px;
    box-sizing: border-box;
  }

  .modal-header {
    height: 40vh;
  }

  .modal-header-image {
    height: 40vh;
    width: 100%;
    object-fit: cover;
  }
    
  .modal-body {
    background-color: black;
  }
  
  .modal-body p {
    font-size: 15px;
    line-height: 1.5;
  }

.banner {
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center;
  flex-direction: column;
  align-items: center;
  height: 300px;
}

.banner-left{
  padding-top: 20px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
  color: white;
  height: 220px;
}

.b-line1 {
  font-size: 30px;
}

.b-line2 {
  text-align: center;
  font-size: 15px;
  padding-top: 20px;
}

.banner-icons {
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 0px;
  justify-content: center;
  flex-direction: row;
}

.banner-icons img{
  width: 35px;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 30px;
}

.b-text-field {
  margin-top: 20px;
  padding: 10px;
  border-radius: 20px;
  border: 2px solid white;
  width: 200px; 
}

.b-inputBox {
  display: flex;
  margin-top: 30px;
  width: 380px;
  padding: 10px;
}

input[type="text"], textarea {
  font-size: 16px;
  width: 330px;
}

.register {
  cursor: pointer;
  font-weight: 100;
  padding-right: 20px;
  font-size: 14px;
}

.invalid {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.8rem;
  padding-top: 10px;
}

.b-footer {
  opacity: 0.5;
  justify-content: center;
  padding-top: 50px;
  position: relative;
  bottom: 30px;
  width: 100%;
}

.b-footerTexts {
  font-size: 15px;
  padding-top: 10px;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: white;
  gap: 30px;
}
}


@media screen and (max-width: 608px) {

  .b-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;
    position: sticky;
    top: 0;
    z-index: 40;
  }

  .b-navbar-links_logo img{
    top: 60px;
    left: 10px;
    width: 70px;
    height: 70px;
    opacity: 1;
  }

  .navbar-links {
    display: none;
  }

  .navbar-menu {
    display: block;
  }

  .b-navbar-lang{
    display: none;
  } 

  .m-nav-button{
    cursor: pointer; 
    margin-top: 30px;
    width: 120px;
    min-height: 38px;
    font-size: 17px;
    background-color: transparent;
    border-radius: 5px 5px 5px 5px;
    border-color: white;
    color: white;
    border: 1px solid #FFFFFF;
  }

  .nav-item a {
    margin-right: 0px;
    font-size: 25px;
    opacity: 0.2;
  }

  .mobile-blog-click {
    font-size: 23px;
    padding-top: 20px;
  }

  .m-navbar-icons {
    display: flex; 
    position: relative;
    flex-direction: row;
    align-items: center;
    padding-top: 30px;
  }

  .m-navbar-icon {
    width: 30px;
    margin: 10px 20px;
  }

  .dropdown {
    position: relative;
    padding-top: 20px;
    display: inline-block;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: black;
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .dropdown-menu li {
    padding: 10px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .dropdown-menu li:hover {
    background-color: black;
    opacity: 0.6;
  }
  
  .dropdown-menu li span {
    margin-right: 10px;
  }
  .page-header {
    height: 450px;
  }
  
  .page-header p {
    font-size: 50px;
  }
  
  .page-header .blog-header {
    font-size: 70px;
  }

  .blog-icons {
    display: none; 
  }

  .category-dropdown {
    position: absolute;
    z-index: 10;
    padding-top: 400px;
    padding-left: 30px;
  }

  .posts {
    color: white;
    display: grid;
    grid-template-columns: 1fr;
    padding: 3rem;
    width: auto; 
    padding-top: 130px;
    margin: 200px auto 0 auto;
    column-gap: 30px;
    justify-content: center;
}
  
  .post {
    width: 90%; 
    margin-left: 5%;
    margin-right: 5%;
    height: 400px;
    background-color: #262626;
  }

  .post-image .overlay-top-left {
    position: absolute;
    width: 56px;
    height: 75px;
    font-size: 20px;
    top: 0px;
    left: 15px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 7px;
    border-radius: 0px;
  }

  .post-contents h3 {
    font-size: 22px;
  }

  .post-description {
    margin-top: 10px;
    font-size: 15px;
  }

  .overlay-top-left2 {
    position: absolute;
    width: 56px;
    height: 75px;
    font-size: 25px;
    top: 0px;
    left: 30px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 7px;
    border-radius: 0px;
  }

  .blog-date {
    font-size: 30px;
  }
  
  .post-image {
    height: 60%;
    position: relative;
  }

  .blog-post-detail {
    position: absolute;
    z-index: 99;
    margin: 0;
    padding: 0;
  }

  .blog-icon-2 img{
    z-index: 30;
    width: 20px;
    margin: 5px;
  }
  
  .share-popup {
    background-color: black;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 25vh;
    width: 70vw;
    border: 1px solid #ddd;
    z-index: 1000;
    padding: 10px;
    box-sizing: border-box;
  }

  .modal-header {
    height: 40vh;
  }

  .modal-header-image {
    height: 40vh;
    width: 100%;
    object-fit: cover;
  }
    
  .modal-body {
    background-color: black;
    color: white;
    padding-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  }
  
  .modal-body p {
    font-size: 15px;
    line-height: 1.5;
  }

.banner {
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center;
  flex-direction: column;
  align-items: center;
  height: 300px;
}

.banner-left{
  padding-top: 20px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
  color: white;
  height: 220px;
}

.b-line1 {
  font-size: 30px;
}

.b-line2 {
  text-align: center;
  font-size: 15px;
  padding-top: 20px;
}

.banner-icons {
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 0px;
  justify-content: center;
  flex-direction: row;
}

.banner-icons img{
  width: 35px;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 30px;
}

.b-text-field {
  margin-top: 20px;
  padding: 10px;
  border-radius: 20px;
  border: 2px solid white;
  width: 200px; 
}

.b-inputBox {
  font-size: 16px;
  display: flex;
  margin-top: 30px;
  width: 380px;
  padding: 10px;
}

input[type="text"], textarea {
  font-size: 16px;
  width: 330px;
}

.register {
  cursor: pointer;
  font-weight: 100;
  font-size: 14px;
  padding-right: 20px;
}

.invalid {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.8rem;
  padding-top: 10px;
}

.b-footer {
  opacity: 0.5;
  justify-content: center;
  padding-top: 50px;
  position: relative;
  bottom: 30px;
  width: 100%;
}

.b-footerTexts {
  font-size: 12px;
  padding-top: 10px;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: white;
  gap: 30px;
}
}